import { mapState, mapActions, mapMutations } from "vuex";

import * as types from "@/types/store";
import { qiwaStatus } from "@/utils/qiwa";
import { PAYMENT_TYPES } from "@/constants";

export default {
  name: "SelectPayment",
  created() {
    this.PAYMENT_TYPES = PAYMENT_TYPES;
    const { transactionId } = this.$route.query;

    if (!transactionId) {
      this.redirectToDashboard();
    } else {
      this.getStatus(transactionId);
    }
  },
  computed: {
    ...mapState({
      loading: state => state.paymentStatus.loading,
      status: state => state.paymentStatus.status,
      priceDetails: state => state.paymentPrice.details,
      loadingPrice: state => state.paymentPrice.loading,
      paymentType: state => state.paymentType.type,
    }),
    status() {
      return qiwaStatus(this.loading || this.loadingPrice);
    },
    isDisabled() {
      return !this.paymentType;
    },
  },
  methods: {
    ...mapActions({
      getPaymentStatus: types.ACTION_PAYMENT_STATUS_GET,
      getPrice: types.ACTION_PAYMENT_PRICE_GET,
    }),
    ...mapMutations({
      setPaymentType: types.MUTATION_PAYMENT_TYPE_SET,
    }),
    async getStatus(id) {
      const [paymentStatusResponse, getPriceResponse] = await Promise.all([
        this.getPaymentStatus(id),
        this.getPrice(id),
      ]);

      if (paymentStatusResponse.error || getPriceResponse.error) {
        const error = paymentStatusResponse.error || getPriceResponse.error;
        const message = error.message || "CORE.INFO.ERROR_OCCURRED";
        this.$buefy.toast.open({ message: this.$t(message), type: "is-danger", duration: 5000 });
        this.redirectToDashboard();
      }
    },
    redirectToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    selectPaymentType(type) {
      this.setPaymentType(type);
    },
    next() {
      const { transactionId } = this.$route.query;
      this.$router.push({ name: "checkout", query: { transactionId } });
    },
  },
};
