import serialize from "serialize-javascript";
import bowser from "bowser";
import customHyperPayStyles from "!css-loader!@/assets/styles/css/customHyperPayStyles.css";

const isChromeIOS = bowser.ios && bowser.chrome;
export const isEdgeIOS = bowser.ios && bowser.msedge;

export const isBlobUrlSupported = !isChromeIOS && !isEdgeIOS;

export const parseBrands = stringifyPaymentWidget => {
  try {
    const brandsIdx = stringifyPaymentWidget.indexOf("brands");
    const brandsArrayStart = stringifyPaymentWidget.indexOf("[", brandsIdx);
    const brandsArrayEnd = stringifyPaymentWidget.indexOf("]", brandsIdx);

    return JSON.parse(stringifyPaymentWidget.substring(brandsArrayStart, brandsArrayEnd + 1));
  } catch (e) {
    return [];
  }
};

export const createBlobUrl = (code, type) => {
  if (isBlobUrlSupported) {
    const blob = new Blob([code], { type });
    return URL.createObjectURL(blob);
  }

  return code;
};

export const createWpwlOptions = lang => {
  return {
    locale: lang,
    style: "plain",
    brandDetection: false,
    onReady() {
      const cardHolder = document.querySelector(".wpwl-control-cardHolder");
      const cardDate = document.querySelector(".wpwl-control-expiry");
      const mada = document.querySelector(".wpwl-control-brand").querySelector('option[value="MADA"]');

      if (mada) {
        const madaDisplayName = {
          en: "mada debit card",
          ar: "بطاقة مدى البنكية",
        };

        mada.text = madaDisplayName[window.wpwlOptions.locale || "en"];
      }

      cardHolder.addEventListener("input", event => {
        window.parent.postMessage({ type: "change", key: "name", value: event.target.value }, "*");
      });

      cardDate.addEventListener("input", event => {
        window.parent.postMessage({ type: "change", key: "date", value: event.target.value }, "*");
      });
    },
    onLoadThreeDIframe() {
      window.parent.postMessage({ type: "hide-card" }, "*");
    },
    onChangeBrand(value, _) {
      window.parent.postMessage({ type: "change", key: "brand", value }, "*");
    },
    onBlurSecurityCode() {
      window.parent.postMessage({ type: "flip", value: false }, "*");
    },
    onReadyIframeCommunication() {
      const IFRAME_VALIDATION_EVENT = "iframeCommunication::setIsValid";
      const IFRAME_FOCUS_EVENT = "iframeCommunication::onFocus";
      const CVV_IFRAME_NAME = "card.cvv";
      const PADDING = 40;

      const isCVVFieldFocused = ({ targetName, data }) => {
        const isCVVIframe = targetName === CVV_IFRAME_NAME;
        const isOnFocusEvent = data?.method === IFRAME_FOCUS_EVENT;

        if (isCVVIframe && isOnFocusEvent) return true;
        return false;
      };

      const sendResizeMsg = value => window.parent.postMessage({ type: "resize", value }, "*");

      const wpwl = document.querySelector(".wpwl-container");

      sendResizeMsg(document.body.scrollHeight);

      // If this event will be registered outside iframe it will cause an inifiiite loop
      window.addEventListener("message", event => {
        if (event.origin !== window.origin) {
          const data = typeof event.data === "string" && JSON.parse(event.data);
          const targetName = event.target?.document?.activeElement?.name;

          if (isCVVFieldFocused({ targetName, data })) {
            window.parent.postMessage({ type: "flip", value: true }, "*");
          }

          if (data?.method === IFRAME_VALIDATION_EVENT) {
            sendResizeMsg(wpwl.scrollHeight + PADDING);
          }
        }

        if (event.data.type === "resize") {
          sendResizeMsg(wpwl.scrollHeight + PADDING);
        }
      });
    },
  };
};

export const generateIframeSourceCreator = createBlobUrl => (paymentWidgetsFunction, { lang, redirectUrl, brands }) => {
  const wpwlOptions = serialize(createWpwlOptions(lang || "en"));
  const dataBrands = brands && Array.isArray(brands) ? brands.join(" ") : "";

  const source = `
    <html dir="${lang === "ar" ? "rtl" : "ltr"}">
      <head>
        <style>
          ${customHyperPayStyles.toString()}
        </style>
        <script>
          window.onerror = function (message, _, __, ___, error) {
            window.parent.postMessage({ type: 'error', value: { message, error } });
          };
        </script>
        <script>
          window.wpwlOptions = ${wpwlOptions}
        </script>
        <script>
         ${paymentWidgetsFunction}
        </script>
      </head>
      <body>
        <form class="paymentWidgets" action="${redirectUrl}" data-brands="${dataBrands}"></form>
      </body>
    </html>
  `;

  return createBlobUrl(source, "text/html");
};

export const createIframeSource = generateIframeSourceCreator(createBlobUrl);
