import "@/assets/styles/css/customHyperPayStyles.css";

import { mapState } from "vuex";
import { logger } from "@/services/logger";

import CreditCard from "@/components/CreditCard/CreditCard.vue";
import ErrorMessage from "@/components/ErrorMessage/ErrorMessage.vue";
import { createIframeSource, parseBrands, isBlobUrlSupported, createWpwlOptions } from "./PaymentForm.utils";
import { registerForm, shouldFlipCard, FLIP_CART_EVENT, createWpwlFallbackOptions } from "./PaymentForm.fallback";
import { removeChar } from "@/utils/string";
import { PAYMENT_TYPES } from "@/constants";

const DEFAULT_IFRAME_HEIGHT = 300;

export default {
  name: "PaymentForm",
  components: {
    CreditCard,
    ErrorMessage,
  },
  props: {
    paymentWidgetsFunction: {
      type: String,
    },
    transactionId: {
      type: String,
    },
  },
  mounted() {
    window.addEventListener("message", this.onMessage);
    window.addEventListener("resize", this.sendResizeInfo);

    this.createForm();
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
    window.removeEventListener("resize", this.sendResizeInfo);
  },
  data: () => ({
    iframeHeight: DEFAULT_IFRAME_HEIGHT,
    form: {
      brand: "",
      name: "",
      date: "",
    },
    flip: false,
    isCardHidden: false,
    defaultCreditBrands: ["VISA", "MASTER"],
    defaultMadaBrands: ["MADA"],
    iframeError: false,
    widgetError: false,
  }),
  computed: {
    ...mapState({
      language: state => state.i18n.language,
      paymentType: state => state.paymentType.type,
    }),
    redirectUrl() {
      return `${window.location.origin}/${this.transactionId}/result`;
    },
    widgetBrands() {
      const brands = this.getBrands(this.normalizedPaymentWidgetsFunction);
      if (brands && Array.isArray(brands)) {
        return brands.join(" ");
      }
      return "";
    },
    normalizedPaymentWidgetsFunction() {
      return this.paymentWidgetsFunction.trim().replace(/;$/, "");
    },
  },
  methods: {
    createGlobalWidget() {
      try {
        this.iframeError = true;

        const parent = this.$refs.formParent;
        registerForm(parent, this.redirectUrl, this.widgetBrands);

        window.wpwlOptions = createWpwlFallbackOptions(this.language);

        this.$nextTick(() => {
          eval(this.normalizedPaymentWidgetsFunction);
        });
      } catch (error) {
        this.widgetError = true;
        logger.logError({ message: `Widget error: ${error}`, error });
      }
    },
    createForm() {
      this.resetCreditCard();

      const paymentWidgetsFunction = this.normalizedPaymentWidgetsFunction;

      const options = {
        lang: this.language,
        redirectUrl: this.redirectUrl,
        brands: this.getBrands(paymentWidgetsFunction),
      };

      const iframeSrc = createIframeSource(paymentWidgetsFunction, options);

      this.$refs.iframe.addEventListener("error", () => {
        const error = "Iframe load error";
        logger.logError({ message: error, error });
      });

      if (isBlobUrlSupported) {
        this.$refs.iframe.setAttribute("src", iframeSrc);
      } else {
        this.$refs.iframe.setAttribute("srcdoc", iframeSrc);
      }
    },
    getBrands(paymentWidgetsFunction) {
      if (!this.paymentType) {
        const brandsFromApi = parseBrands(paymentWidgetsFunction);

        if (brandsFromApi && brandsFromApi.length) {
          return brandsFromApi;
        }

        return this.defaultCreditBrands;
      }

      if (this.paymentType === PAYMENT_TYPES.mada) {
        return this.defaultMadaBrands;
      }

      if (this.paymentType === PAYMENT_TYPES.credit) {
        return this.defaultCreditBrands;
      }

      return this.defaultCreditBrands;
    },
    sendResizeInfo() {
      this.$refs.iframe.contentWindow.postMessage({ type: "resize" }, window.origin);
    },
    onMessage(event) {
      if (event.origin !== window.origin) {
        if (shouldFlipCard(event)) {
          this.handleMessage(FLIP_CART_EVENT);
        }
        return;
      }

      const { data } = event;
      this.handleMessage(data);
    },
    handleMessage(data) {
      if (data.type === "resize") {
        this.calculateHeight(data.value);
        return;
      }

      if (data.type === "flip") {
        this.flip = data.value;
        return;
      }

      if (data.type === "change") {
        this.changeFormValue(data.key, data.value);
        return;
      }

      if (data.type === "hide-card") {
        this.isCardHidden = true;
        return;
      }

      if (data.type === "error") {
        const { message, error } = data.value;
        this.createGlobalWidget();
        logger.logError({ message: `Iframe error: ${message}`, error });
      }
    },
    calculateHeight(value) {
      this.iframeHeight = value || DEFAULT_IFRAME_HEIGHT;
    },
    changeFormValue(key, value) {
      let formattedValue = value;

      if (key === "date") {
        const CHAR = "/";
        formattedValue = value.includes(CHAR) ? removeChar(value, CHAR) : value;
      }

      this.form[key] = formattedValue;
    },
    resetCreditCard() {
      this.form = {
        brand: "",
        name: "",
        date: "",
      };
    },
  },
  watch: {
    language() {
      if (this.iframeError) {
        this.createGlobalWidget();
      } else {
        this.createForm();
      }
    },
  },
};
