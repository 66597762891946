// Some users have an issue with payment form in iframe, this functions provide a workaround for this issue which we where not able to recreate

import { createWpwlOptions } from "./PaymentForm.utils";

const IFRAME_FOCUS_EVENT = "iframeCommunication::onFocus";
const CVV_IFRAME_NAME = "card.cvv";
export const FLIP_CART_EVENT = { type: "flip", value: true };

export const removeAllChildren = element => {
  while (element.firstChild) {
    element.firstChild.remove();
  }
};

export const registerForm = (parent, redirectUrl, widgetBrands) => {
  removeAllChildren(parent);

  const formEl = document.createElement("form");
  formEl.setAttribute("class", "paymentWidgets");
  formEl.setAttribute("action", redirectUrl);
  formEl.setAttribute("data-brands", widgetBrands);

  parent.appendChild(formEl);
};

export const shouldFlipCard = event => {
  const data = typeof event.data === "string" && JSON.parse(event.data);
  const isOnFocusEvent = data?.method === IFRAME_FOCUS_EVENT;
  const targetName = event.target?.document?.activeElement?.name;

  return isOnFocusEvent && targetName === CVV_IFRAME_NAME;
};

export const createWpwlFallbackOptions = lang => {
  return {
    ...createWpwlOptions(lang),
    onReadyIframeCommunication() {
      const sendResizeMsg = value => window.parent.postMessage({ type: "resize", value }, "*");
      sendResizeMsg(document.body.scrollHeight);
    },
  };
};
