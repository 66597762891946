import { mapState, mapGetters, mapActions } from "vuex";

import PaymentPrice from "@/components/PaymentPrice/PaymentPrice.vue";
import PaymentForm from "@/components/PaymentForm/PaymentForm.vue";
import * as types from "@/types/store";
import { qiwaStatus } from "@/utils/qiwa";

export default {
  name: "Checkout",
  components: {
    PaymentPrice,
    PaymentForm,
  },
  created() {
    const { transactionId } = this.$route.query;

    if (!transactionId) {
      this.redirectToDashboard();
    } else {
      this.createPayment(transactionId);
    }
  },
  computed: {
    ...mapState({
      priceDetails: state => state.paymentPrice.details,
      loadingPayment: state => state.payment.loading,
      loadingPrice: state => state.paymentPrice.loading,
      paymentType: state => state.paymentType.type,
    }),
    ...mapGetters({
      paymentWidgetsFunction: "getPaymentWidgetsFunction",
      paymentReference: "getPaymentReference",
    }),
    status() {
      return qiwaStatus(this.loadingPrice || this.loadingPayment);
    },
    transactionId() {
      const { transactionId } = this.$route.query;
      return transactionId;
    },
  },
  methods: {
    ...mapActions({
      makePayment: types.ACTION_MAKE_PAYMENT_GET,
      getPrice: types.ACTION_PAYMENT_PRICE_GET,
    }),
    redirectToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    async createPayment(id) {
      if (!this.paymentType) {
        const { transactionId } = this.$route.query;
        this.$router.push({ name: "payment", query: { transactionId } });
        return;
      }

      const [makePaymentResponse, getPriceResponse] = await Promise.all([
        this.makePayment({ id, paymentType: this.paymentType }),
        this.getPrice(id),
      ]);

      if (makePaymentResponse.error || getPriceResponse.error) {
        const error = makePaymentResponse.error || getPriceResponse.error;
        const message = error.message || "CORE.INFO.ERROR_OCCURRED";
        this.$buefy.toast.open({ message: this.$t(message), type: "is-danger", duration: 5000 });
        this.redirectToDashboard();
      }
    },
  },
};
